import 'regenerator-runtime/runtime'
import App  from './js/App.js';
import { Navigator } from './js/navigator/Navigator.js';


let data = {
  
  steps : {
    ["home"]: {
      type : "home",
      next : "intro",
    },
    ["intro"]: {
      type : "video",
      file: "Intro + Scène 1",
      end : "next_end",
      next : "quizz1",
      previous : "home",
    },
    // QUIZZ 1
    ["quizz1"]: {
      type : "video",
      file: "Quizz 1",
      end : "pause",
      buttons : [
        { buttonSize : 30, buttonPosition : {x : 176, y : -86, z : 35}, localizable : false, buttonId : "transparent", target : "quizz1Bad1"},// 1300
        { buttonSize : 30, buttonPosition : {x : 154, y : -86, z : 90}, localizable : false, buttonId : "transparent", target : "quizz1Good"}, // 3000 Bonne réponse
        { buttonSize : 30, buttonPosition : {x : 116, y : -86, z : 135}, localizable : false, buttonId : "transparent", target : "quizz1Bad2"} // 9000
      ],
      next : "quizz1Good",
      previous : "intro",
    },
    ["quizz1Bad1"]: {
      type : "video",
      file: "Mauvaise Réponse Quizz 1 (1300)",
      end : "next_end",
      next : "quizz1",
    },
    ["quizz1Good"]: {
      type : "video",
      file: "Bonne Réponse Quizz 1 + Scène 2 (Partie 1)",
      end : "pause",
      buttons : [{ buttonSize : 30, buttonPosition : { x: 22, y: -28, z: -187 },localizable : false, buttonId : "circle", target : "2b2"}],
      next : "2b2",
    },
    ["quizz1Bad2"]: {
      type : "video",
      file: "Mauvaise Réponse Quizz 1 (9000)",
      end : "next_end",
      next : "quizz1",
    },
    // FIN QUIZZ
    ["2b2"]: {
      type : "video",
      file: "Scène 2b2 + Scène 3 (Partie 1) + Express Snoot A",
      end : "pause",
      buttons : [{ buttonSize : 30, buttonPosition : { x: -53, y: -52, z: -186 },localizable : false, buttonId : "circle", target : "3and4"}],
      next : "3and4",
      previous : "quizz1",
    },
    ["3and4"]: {
      type : "video",
      file: "Express Snoot B + Scène 3 (Partie 2) + Scène 4 (Partie 1)",
      end : "pause",
      buttons : [{ buttonSize : 100, buttonPosition : {x: -55, y: -63, z: -181},localizable : true, buttonId : "continue_over", target : "4c"}],
      next : "4c",
      previous : "2b2",
    },
    ["4c"]: {
      type : "video",
      file: "Scène 4c",
      end : "pause",
      buttons : [{ buttonSize : 100, buttonPosition : {x : 55, y : -31, z : -189},localizable : true, buttonId : "continue_over", target : "4d"}],
      next : "4d",
      previous : "3and4",
    },
    ["4d"]: {
      type : "video",
      file: "Scène 4d",
      end : "pause",
      buttons : [{ buttonSize : 100, buttonPosition : {x : 0, y : -49, z : -194},localizable : true, buttonId : "continue_over", target : "quizz2"}],
      next : "quizz2",
      previous : "4c",
    },
    // QUIZZ 2
    ["quizz2"]: {
      type : "video",
      file: "Quizz 2",
      end : "pause",
      buttons : [
        { buttonSize : 30, buttonPosition : {x : -59, y : -86, z : -171}, localizable : false, buttonId : "transparent", target : "quizz2Good"}, // Marseille => bonne réponse
        { buttonSize : 30, buttonPosition : {x : 0, y : -85, z : -181}, localizable : false, buttonId : "transparent", target : "quizz2Bad1"}, // Lyon
        { buttonSize : 30, buttonPosition : {x : 58, y : -86, z : -171}, localizable : false, buttonId : "transparent", target : "quizz2Bad2"} // Lille
      ],
      next : "quizz2Good",
      previous : "4d",
    },
    ["quizz2Good"]: {
      type : "video",
      file: "Bonne Réponse Quizz 2 (Marseille)",
      end : "next_end",
      next : "quizz4",
    },
    ["quizz2Bad1"]: {
      type : "video",
      file: "Mauvaise Réponse Quizz 2 (Lyon)",
      end : "next_end",
      next : "quizz2",      
    },
    ["quizz2Bad2"]: {
      type : "video",
      file: "Mauvaise Réponse Quizz 2 (Paris)",
      end : "next_end",
      next : "quizz2",    
    },
    // QUIZZ 4
    ["quizz4"]: {
      type : "video",
      file: "Quizz 4",
      end : "pause",
      buttons : [
        { buttonSize : 30, buttonPosition : {x : -59, y : -86, z : -171}, localizable : false, buttonId : "transparent", target : "quizz4Bad1"}, // Marseille
        { buttonSize : 30, buttonPosition : {x : 0, y : -85, z : -181}, localizable : false, buttonId : "transparent", target : "quizz4Bad2"}, // Rennes
        { buttonSize : 30, buttonPosition : {x : 58, y : -86, z : -171}, localizable : false, buttonId : "transparent", target : "quizz4Good"} // J'ai besoin d'aide => bonne réponse
      ],
      next : "quizz4Good",
      previous : "quizz2",
    },
    ["quizz4Bad1"]: {
      type : "video",
      file: "Mauvaise Réponse Quizz 4 (Marseille)",
      end : "next_end",
      next : "quizz4",    
    },
    ["quizz4Bad2"]: {
      type : "video",
      file: "Mauvaise Réponse Quizz 4 (Rennes)",
      end : "next_end",
      next : "quizz4"
    },
    ["quizz4Good"]: {
      type : "video",
      file: "Bonne Réponse Quizz 4 (Besoin d_aide) + Scènes 5-6-7bis",
      end : "next_end",
      next : "outro",
      previous : "quizz4",   
    },
    ["outro"]: { 
      type : "video",
      file: "Outro",
      end : "next_end",
      next : "home",  
      previous : "quizz4Good",  
    },
  },
}
const params = {};
const url = new URL(window.location.href);
let searchParams = new URLSearchParams(url.search);
searchParams.forEach(function(value, key) {
     if(value == "false"){
         value = false;
     }
     else if(value == "true"){
         value = true;
     }
     else if (!isNaN(Number(value)) ){
    	 value = Number(value);
     }
    params[key] = value;
});



let app = new App(data,params);




